.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--content-width);
  width: 100%;
  min-height: 150px;
  margin: 0 auto;
  padding: 0 var(--content-padding);
  box-sizing: border-box;
}

.logo {
  display: block;
  text-decoration: none;
  width: 220px;
  min-height: 75px;
  background: url(../../assets/images/logo.png) no-repeat;
  background-size: contain;
}

.contactUsButton {
  display: block;
  width: 183px;
  height: 56px;
  line-height: 56px;
  background: linear-gradient(94.57deg, #FF77EF 8.81%, #AA52F8 93.67%);
  box-shadow: 4px 4px 15px 0 #525AE233;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 900;
  color: var(--white);
  text-align: center;
  border-radius: 28px;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 576px) {
  .header {
    justify-content: right;
    min-height: auto;
    padding-top: 24px;
  }

  .header .contactUsButton {
    display: none;
  }
}