.advantageCard {
  height: 220px;
  padding: 24px;
  border-radius: 30px;
  background-color: var(--white);
  border-right: 30px;
  box-shadow: 4px 4px 20px 0 #03517C26;
  box-sizing: border-box;
  background-repeat: no-repeat;
}

.mobileVisible {
  display: none;
}

.advantageCard:hover {
  background: none !important;
}

.advantageTitle {
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.graySmallFont {
  display: none;
  width: auto;
  margin-top: 16px;
  text-align: start;
  font-size: 20px;
  color: var(--gray);
  line-height: 40px;
}

.advantageCard:hover .graySmallFont {
  display: block;
}

@media (max-width: 576px) {
  .mobileHidden {
    display: none;
  }

  .mobileVisible {
    display: block;
  }

  .advantageTitle {
    position: relative;
    font-size: 16px;
    cursor: pointer;
    border: 0 !important;
  }

  .advantageTitle:after {
    content: "";
    position: absolute;
    right: 0;
    top: 9px;
    width: 12px;
    height: 7px;
    background: url(../../assets/images/purple-arrow.svg) no-repeat;
    background-size: cover;
    transition: transform .3s;
  }

  .advantageTitle:global(.ant-collapse-item-active):after {
    transform: rotate(180deg);
  }

  .advantageTitle > div {
    padding: 0 !important;
  }

  .advantageTitle p {
    margin-top: 10px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: var(--gray);
    text-transform: none;
  }

  .advantageCard :global(.ant-collapse-expand-icon) {
    display: none !important;
  }

  .advantageCard :global(.ant-collapse-content) {
    border: 0 !important;
  }

  .advantageCard :global(.ant-collapse-content-box) {
    padding: 0 !important;
  }

  .advantageCard {
    transition: 2s;
    height: auto;
    padding: 0;
    border: 0;
  }
}