.wrapper {

}

.mobileVisible {
  display: none;
}

.bannerSection {
  background: url(../../assets/images/banner-background.png) no-repeat;
  background-position-x: 50vw;
  background-size: 968px;
}

.sectionContent {
  max-width: var(--content-width);
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--content-padding);
  box-sizing: border-box;
}

.bannerContent {
  height: 812px;
  padding-top: 115px;
}

.mainTitle {
  max-width: 910px;
  font-size: 64px;
  font-weight: 800;
  text-transform: uppercase;
}

.graySmallFont {
  font-size: 20px;
  color: var(--gray);
  line-height: 40px;
}

.bannerDescription {
  max-width: 592px;
  margin-top: 64px;
}

.contactUsButton {
  display: block;
  width: 183px;
  height: 56px;
  margin-top: 48px;
  line-height: 56px;
  background: linear-gradient(94.57deg, #FF77EF 8.81%, #AA52F8 93.67%);
  box-shadow: 4px 4px 15px 0 #525AE233;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 900;
  color: var(--white);
  text-align: center;
  text-decoration: none;
  border-radius: 28px;
  cursor: pointer;
}

.proofSection {
  margin-top: 48px;
}

.title {
  font-size: 48px;
  font-weight: 800;
  text-transform: uppercase;
}

.proofSection .title {
  text-align: center;
}

.proofCompanies {
  margin-top: 24px;
  display: flex;
  gap: 97px;
  flex-wrap: wrap;
  justify-content: center;
}

.proofLogo {
  display: block;
  width: 119px;
  height: 126px;
  object-fit: contain;
}

.expertiseSection {
  margin-top: 0;
}

.expertiseItems {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  grid-column-gap: 20px;
  grid-row-gap: 48px;
  margin-top: 24px;
}

.expertiseDescription {
  max-width: 590px;
  width: 100%;
}

.expertiseDescription.secondDescription {
  padding-left: 25px;
  box-sizing: border-box;
}

.expertiseInfo {
  max-width: 285px;
  width: 100%;
  height: 169px;
  padding: 24px 100px 24px 24px;
  object-fit: contain;
  background: url(../../assets/images/bubble.png) no-repeat;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.expertiseInfo:nth-child(2) {
  background-size: 112px;
  background-position: 123px 26px;
}

.expertiseInfo:nth-child(3) {
  background-size: 95px;
  background-position: 141px 82px;
}

.expertiseInfo:nth-child(4) {
  background-size: 139px;
  background-position: 145px 45px;
}

.expertiseInfo:nth-child(4) .expertiseTitle {
  width: 150px;
}

.expertiseInfo:nth-child(5) {
  background-size: 95px;
  background-position: 138px 26px;
}

.expertiseInfo:nth-child(6), .expertiseInfo:nth-child(10) {
  background-size: 111px;
  background-position: 29px 73px;
}

.expertiseInfo:nth-child(7), .expertiseInfo:nth-child(9), .expertiseInfo:nth-child(11) {
  background-size: 161px;
  background-position: 111px 24px;
}

.expertiseInfo:nth-child(8) {
  background-size: 175px;
  background-position: 84px 0;
}

.expertiseItems .contactUsButton {
  position: absolute;
  right: 0;
  bottom: -70px;
}

.resourceSection {
  margin-top: 250px;
  background: url(../../assets/images/resource-background.png) no-repeat right;
  background-size: 848px;
  background-position-x: calc((100vw - var(--content-width)) / 2 + 507px);
  background-position-y: -130px;
}

.resourceSection .title {
  margin-top: 48px;
  text-align: center;
}

.resourceSection .technologiesTitle {
  margin-top: 64px;
}

.resourceSection .graySmallFont {
  max-width: 580px;
  margin-top: 24px;
}

.resourceSection .technologiesDescription {
  max-width: 795px;
  margin: 24px auto 0;
  text-align: center;
}

.technologiesFirst {
  display: flex;
  gap: 132px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 74px;
}

.technologiesBlock {
  margin-top: 96px;
}

.technologiesFirst .technologiesBlock {
  margin-top: 0;
}

.technologyType {
  background-image: var(--purple-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 57px;
  margin-top: 24px;
}

.technologies.blockchain {
  margin-top: 50px;
}

.technologyLogo {
  width: 82px;
  height: 82px;
  object-fit: contain;
}

.specialistsSection {
  margin-top: 96px;
  background: url(../../assets/images/bubbles.png) no-repeat right 100px;
  background-size: 390px;
}

.specialistsSection .title {
  text-align: center;
}

.specialistsSection .graySmallFont {
  max-width: 794px;
  margin: 18px auto 0;
  text-align: center;
}

.specialistsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;
  row-gap: 24px;
  margin-top: 96px;
}

.software {
  background: url(../../assets/images/specialist-software.png) no-repeat;
  background-size: cover;
}

.architect {
  background: url(../../assets/images/specialist-architects.png) no-repeat;
  background-size: cover;
}

.qa {
  background: url(../../assets/images/specialist-qa.png) no-repeat;
  background-size: cover;
}

.devops {
  background: url(../../assets/images/specialist-devops.png) no-repeat;
  background-size: cover;
}

.blockchainExpert {
  background: url(../../assets/images/specialist-blockchain.png) no-repeat;
  background-size: cover;
}

.cto {
  background: url(../../assets/images/specialist-cto.png) no-repeat;
  background-size: cover;
}

.manager {
  background: url(../../assets/images/specialist-manager.png) no-repeat;
  background-size: cover;
}

.analyst {
  background: url(../../assets/images/specialist-analysts.png) no-repeat;
  background-size: cover;
}

.specialistCard {
  max-width: 386px;
  width: 100%;
  height: 311px;
  padding: 24px;
  border-radius: 30px;
  box-shadow: 4px 4px 20px 0 #03517C26;
  background-color: var(--white);
  box-sizing: border-box;
}

.stub {
  max-width: 387px;
  width: 100%;
  height: 311px;
}

.specialistTitle {
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.specialistCard .graySmallFont {
  display: none;
  width: auto;
  text-align: start;
}

.specialistCard:hover {
  background-image: none;
}

.specialistCard:hover .graySmallFont {
  display: block;
}

.advantagesSection {
  margin-top: 186px;
}

.advantagesSection .title {
  text-align: center;
}

.advantagesList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;
  row-gap: 24px;
  margin-top: 24px;
}

.advantagesList .graySmallFont {
  max-width: 590px;
  padding-right: 5px;
  box-sizing: border-box;
}

.advantagesList .secondRow {
  margin-top: 25px;
}

.advantageCard {
  max-width: 590px;
  width: 100%;
}

.advantageCard > div {
  background-color: var(--white);;
  box-shadow: 4px 4px 20px 0 #03517C26;
  box-sizing: border-box;
  background-repeat: no-repeat;
}

.advantageCard:nth-child(2) > div {
  background-image: url(../../assets/images/bagel.png);
  background-size: 520px;
  background-position: 50px bottom;
}

.advantageCard:nth-child(3) > div {
  background-image: url(../../assets/images/bagel-1.png);
  background-size: 389px;
  background-position: 202px;
}

.advantageCard:nth-child(4) > div {
  background-image: url(../../assets/images/bagel-2.png);
  background-size: 385px;
  background-position: left bottom;
}

.advantageCard:nth-child(5) > div {
  background-image: url(../../assets/images/bagel-3.png);
  background-size: 420px;
  background-position: left bottom;
}

.advantageCard:nth-child(6) > div {
  background-image: url(../../assets/images/bagel-4.png);
  background-size: 515px;
  background-position: 40px 20px;
}

.advantageCard:nth-child(7) > div {
  background-image: url(../../assets/images/bagel-5.png);
  background-size: 495px;
  background-position: 90px bottom;
}

.advantageCard:nth-child(8) > div {
  background-image: url(../../assets/images/bagel-6.png);
  background-size: 360px;
  background-position: right bottom;
}

.advantageCard:hover > div {
  background-image: none;
}

.hireSection {
  padding: 0;
  margin-top: 190px;
}

.hireSection .title {
  text-align: center;
}

.hireSection .expertiseInfo:nth-child(2) {
  padding-right: 80px;
}

.hireSection .graySmallFont:nth-child(12), .hireSection .graySmallFont:nth-child(13) {
  padding-top: 48px;
}

.purpleFont {
  background: linear-gradient(94.57deg, #FF77EF 8.81%, #AA52F8 93.67%), linear-gradient(0deg, #918B9C, #918B9C);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.servicesSection {
  margin-top: 96px;
}

.servicesSection .title {
  text-align: center;
}

.listening {
  background: url(../../assets/images/listening.png) no-repeat;
  background-size: cover;
}

.choosing {
  background: url(../../assets/images/choosing.png) no-repeat;
  background-size: cover;
}

.interviewing {
  background: url(../../assets/images/interviewing.png) no-repeat;
  background-size: cover;
}

.contract {
  background: url(../../assets/images/contract.png) no-repeat;
  background-size: cover;
}

.onboarding {
  background: url(../../assets/images/onboarding.png) no-repeat;
  background-size: cover;
}

.laptop {
  background: url(../../assets/images/laptop.png) no-repeat;
  background-size: cover;
}

.servicesSection .graySmallFont {
  margin-top: 18px;
}

.studiesSection {
  margin-top: 190px;
}

.studiesSection .description {
  max-width: 590px;
  margin-top: 24px;
}

.studyRow {
  display: flex;
  gap: 20px;
  margin-top: 48px;
}

.caseStudyImage {
  max-width: 590px;
  width: 100%;
}

.studyInfoBlock {
  max-width: 590px;
  width: 100%;
  padding: 24px;
}

.studyTitle {
  font-size: 24px;
  font-weight: 800;
}

.studyInfoBlock .purpleFont {
  margin-top: 24px;
  font-size: 20px;
  font-weight: 800;
}

.studyInfoBlock .graySmallFont {
  margin-top: 24px;
}

.studyButton {
  width: 300px;
  margin-top: 24px;
  float: right;
}

.studyInfoBlock.withShadow {
  box-shadow: 4px 4px 20px 0 #03517C26;
  border-radius: 30px;
  background-color: var(--white);
}

.studyRow .bubbles {
  padding: 0;
  background: url(../../assets/images/bubbles-2.png) no-repeat center;
  background-size: 380px;
}

.studyRow:last-child {
  margin-top: 0;
}

.faqSection {
  margin-top: 190px;
  background: url(../../assets/images/bubble.png) no-repeat right 128px,
              url(../../assets/images/bubbles.png) no-repeat right 35px top 408px;
  background-size: 125px, 200px;
}

.testimonialsSection {
  max-width: var(--content-width);
  margin: 190px auto 0;
}

.testimonialsSection .title {
  padding: 0 var(--content-padding);
}

.testimonialsSection .graySmallFont {
  max-width: 590px;
  margin-top: 24px;
  padding: 0 var(--content-padding);
  box-sizing: border-box;
}

.faqsContent {
  display: flex;
  flex-direction: column;
  gap: 38px;
  max-width: 1016px;
  margin-top: 32px;
}

.slider {
  position: relative;
  width: 1245px;
  /*width: 1230px;*/
  margin-top: 48px;
}

.slider :global(.slick-track) {
  display: flex;
}

.testimonialWrapper {
  height: 525px;
  width: 1245px;
  padding: 20px 30px 20px 157px;
  box-sizing: border-box;
  overflow: hidden;
}

.testimonialsBlock {
  position: relative;
  height: 100%;
  padding: 24px 40px 0 265px;
  border-radius: 30px;
  box-shadow: 4px 4px 20px 0 #03517C26;
  box-sizing: border-box;
}

.testimonialPhoto {
  position: absolute;
  top: 24px;
  left: -140px;
  width: 285px;
  height: 285px;
  border-radius: 30px;
  box-shadow: 4px 4px 20px 0 #03517C26;
}

.testimonialName {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
}

.slider .purpleFont {
  margin-top: 24px;
  font-size: 20px;
  font-weight: 800;
}

.slider .graySmallFont {
  margin-top: 34px;
  max-width: none;
  padding: 0;
}

.sliderPage {
  position: absolute;
  top: 355px;
  left: 107px;
  width: 100px;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
}

.sliderPrev {
  position: absolute;
  top: 358px;
  left: 15px;
  width: 80px;
  height: 24px;
  background: url(../../assets/images/slider-gray-arrow.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
  z-index: 1;
}

.sliderNext {
  position: absolute;
  top: 358px !important;
  left: 220px;
  width: 80px;
  height: 24px;
  background: url(../../assets/images/slider-purple-arrow.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
  z-index: 1;
}



.tellUsSection {
  margin-top: 190px;
}

.tellUsBlock {
  display: flex;
  gap: 20px;
  margin-top: 48px;
}

.tellUsLeft {
  max-width: 590px;
  width: 100%;
  background: url(../../assets/images/follow-us.svg) no-repeat bottom 13px left 0;
  background-size: 522px;
}

.tellUsLeft .graySmallFont {
  padding-right: 10px;
}

.simplyFill {
  margin-top: 28px;
  font-weight: 800;
}

.followUs {
  margin-top: 75px;
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.socialLinks {
  display: flex;
  gap: 48px;
  margin-top: 24px;
}

.socialLink {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.linkedIn {
  background-image: url(../../assets/images/linkedin.svg);
}

.instagram {
  background-image: url(../../assets/images/instagram.svg);
}

.twitter {
  background-image: url(../../assets/images/twitter.svg);
  width: 26px;
}

.dribbble {
  background-image: url(../../assets/images/dribbble.svg);
}

.behance {
  background-image: url(../../assets/images/behance.svg);
  width: 26px;
}

@media (min-width: 1920px) {
  .bannerSection {
    background-position-x: right;
  }
}

@media (max-width: 1271px) {
  .slider {
    width: auto;
  }

  .testimonialWrapper {
    padding-right: 15px;
  }
}

@media (max-width: 1242px) {
  .stub {
    display: none;
  }

  .studyRow {
    flex-direction: column;
    align-items: center;
  }

  .studyRow:nth-child(even) {
    flex-direction: column-reverse;
  }

  .studyInfoBlock.withShadow {
    box-shadow: none;
  }

  .studyRow .bubbles {
    height: 99px;
    margin-top: 30px;
    background-size: 158px;
  }

  .studyRow:last-child .graySmallFont {
    margin-top: 0;
  }

  .studyRow:last-child {
    gap: 10px;
  }

  .studyRow .studyInfoBlock {
    padding: 0;
  }

  .slider, .testimonialWrapper {
    max-width: calc(var(--content-width) + 12px);
    width: calc(100vw - 12px);
  }
}

@media (max-width: 1151px) {
  .testimonialWrapper {
    height: 565px;
  }

  .tellUsLeft {
    background-size: 100%;
  }
}

@media (max-width: 1069px) {
  .testimonialWrapper {
    height: 605px;
  }
}

@media (max-width: 1020px) {
  .testimonialWrapper {
    height: 645px;
  }
}

@media (max-width: 991px) {
  .mobileHidden {
    display: none;
  }

  .mobileVisible {
    display: block;
  }

  .mainTitle {
    font-size: 32px;
  }

  .bannerSection {
    background-size: 492px;
    background-position-x: 51vw;

  }

  .bannerContent {
    height: auto;
    padding-bottom: 50px;
  }

  .bannerDescription {
    max-width: 60vw;
  }

  .title {
    font-size: 32px;
  }

  .proofSection .title {
    text-align: left;
  }

  .expertiseSection {
   margin-top: 120px;
  }

  .expertiseDescription.secondDescription {
    padding-left: 0;
  }

  .resourceSection {
    margin-top: 200px;
  }

  .technologiesFirst {
    margin-top: 30px;
    row-gap: 30px;
  }

  .technologiesBlock {
    margin-top: 30px;
  }

  .specialistsSection {
    margin-top: 70px;
    background: none;
  }

  .specialistsSection .title {
    text-align: left;
  }

  .specialistsSection .graySmallFont {
    text-align: left;
    margin-left: 0;
  }

  .specialistsList {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    max-width: calc(100vw - 42px);
    padding: 30px 0;
    margin-top: 0;
  }

  .specialistsList::-webkit-scrollbar-track {
    background-color: #EAE7F0;
    border-radius: 2px;
    overflow: hidden;
  }

  .specialistsList::-webkit-scrollbar {
    height: 6px;
  }

  .specialistsList::-webkit-scrollbar-thumb {
    min-width: 74px;
    border-radius: 2px;
    background-image: var(--purple-gradient);
  }

  .specialistCard {
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
    background: none;
  }

  .specialistCard:first-child {
    margin-left: 0;
  }

  .specialistCard .specialistTitle {
    font-size: 16px;
    white-space: normal;
  }

  .specialistCard .graySmallFont {
    display: block;
    white-space: normal;
  }

  .advantagesSection {
    margin-top: 70px;
  }

  .sectionContent {
    margin-top: 70px;
  }

  .studiesSection {
    margin-top: 70px;
  }

  .faqSection {
    margin-top: 70px;
    background: none;
  }

  .testimonialsSection {
    margin-top: 70px;
  }

  .testimonialWrapper {
    height: 435px;
    padding: 120px 15px 40px;
  }

  .testimonialsBlock {
    padding: 15px 15px 20px;
  }

  .testimonialPhoto {
    top: -120px;
    left: 0;
    width: 100px;
    height: 100px;
  }

  .testimonialName {
    position: absolute;
    top: -120px;
    left: 120px;
  }

  .slider .purpleFont {
    position: absolute;
    top: -105px;
    left: 120px;
  }

  .slider .graySmallFont {
    margin-top: 0;
  }

  .sliderPrev {
    bottom: 0;
    left: 23px;
    top: unset;
  }

  .sliderNext {
    bottom: 0;
    right: 23px;
    left: unset;
    top: unset !important;
  }

  .sliderPage {
    left: calc(50% - 50px);
    bottom: 0;
    top: unset;
  }

  .tellUsSection {
    margin-top: 70px;
  }

  .tellUsBlock {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 300px;
    background: url(../../assets/images/follow-us.svg) no-repeat center bottom;
    background-size: 522px;
  }

  .tellUsLeft {
    background: none;
    max-width: none;
  }

  .tellUsLeft .graySmallFont {
    max-width: none;
  }

  .tellUsLeft .simplyFill {
    margin-top: 20px;
  }
}

.successModalBody {
  height: 241px;
  padding-top: 190px;
  background: url(../../assets/images/follow-us.svg) no-repeat center top;
  background-size: 435px;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  box-sizing: border-box;
}

.thanksModalBody {
  height: 236px;
  padding-top: 5px;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  font-weight: 800;
}

.thanksModalBody a {
  border-bottom: 1px solid #AA52F8;
}

.thanksModalBody a:hover {
  border-bottom: none;
}


@media (max-width: 975px) {
  .testimonialWrapper {
    height: 515px;
  }
}

@media (max-width: 768px) {
  .graySmallFont {
    font-size: 16px;
    line-height: 19px;
  }

  .bannerSection {
    background-position-x: center;
    background-size: 98vw;
    padding-top: 80vw;
  }

  .bannerContent {
    margin-top: 20px;
    padding-bottom: 0;
    padding-top: 0;
  }

  .bannerDescription {
    max-width: none;
  }

  .proofSection {
    margin-top: 70px;
  }

  .expertiseSection {
    margin-top: 70px;
  }

  .expertiseItems {
    gap: 0;
  }

  .expertiseItems .contactUsButton {
    bottom: -80px;
  }

  .expertiseItems .expertiseDescription {
    padding-bottom: 30px;
  }

  .expertiseDescription.secondDescription {
    padding-top: 30px;
    padding-bottom: 0;
  }

  .expertiseInfo {
    max-width: 165px;
    height: 98px;
    font-size: 16px;
    padding: 20px 45px 20px 14px;
  }

  .expertiseInfo:nth-child(2) {
    background-size: 67px;
    background-position: 8px 32px;
  }

  .expertiseInfo:nth-child(3) {
    background-size: 95px;
    background-position: 49px 12px;
  }

  .expertiseInfo:nth-child(4) {
    background-size: 97px;
    background-position: 57px 5px;
  }

  .expertiseInfo:nth-child(5) {
    background-size: 63px;
    background-position: 103px 38px;
  }

  .expertiseInfo:nth-child(6), .expertiseInfo:nth-child(10) {
    background-size: 72px;
    background-position: 7px 12px;
  }

  .expertiseInfo:nth-child(7), .expertiseInfo:nth-child(11) {
    background-size: 105px;
    background-position: 53px -1px;
  }

  .expertiseInfo:nth-child(8) {
    padding-top: 32px;
    background-size: 107px;
    background-position: 46px -4px;
  }

  .expertiseInfo:nth-child(9) {
    padding-top: 32px;
    background-size: 49px;
    background-position: 34px 4px;
  }

  .resourceSection {
    margin-top: 160px;
  }

  .resourceSection .title {
    text-align: left;
  }

  .resourceSection .technologiesDescription {
    text-align: left;
  }

  .specialistCard {
    width: 270px;
    height: 200px;
    padding: 15px;
  }

  .specialistCard .graySmallFont {
    margin-top: 10px;
  }

  .advantagesSection .title {
    text-align: left;
  }

  .hireSection .title {
    text-align: left;
  }

  .hireSection .graySmallFont {
    font-size: 20px;
    line-height: 24px;
  }

  .hireSection .graySmallFont:nth-child(12), .hireSection .graySmallFont:nth-child(12) {
    padding-top: 30px;
    padding-bottom: 0;
  }

  .faqsContent {
    gap: 20px;
  }

  .slider {
    margin-top: 30px;
  }

  .sliderPage {
    font-size: 16px;
  }

  .testimonialWrapper {
    height: 340px;
  }

  .testimonialName {
    font-size: 16px;
    line-height: 19px;
  }

  .slider .purpleFont {
    top: -112px;
    font-size: 16px;
    line-height: 19px;
  }

  .tellUsBlock {
    gap: 10px;
  }

  .tellUsLeft .simplyFill {
    font-size: 16px;
    line-height: 19px;
  }

  .followUs {
    margin-top: 70px;
  }

  .successModalBody {
    height: 185px;
    padding-top: 145px;
    background-size: 100%;
    font-size: 16px;
    line-height: 19px;
  }

  .thanksModalBody {
    height: 145px;
    padding: 40px 15px 0;
    font-size: 16px;
    line-height: 19px;
  }
}

@media (max-width: 600px) {
  .testimonialWrapper {
    height: 360px;
  }
}

@media (max-width: 576px) {
  .bannerSection {
    background-position: top -5px right 0vw;
    background-size: 120vw;
    padding-top: 90vw;
    padding-bottom: 0;
  }

  .bannerDescription {
    font-size: 20px;
    margin-top: 20px;
    line-height: 24px;
  }

  .contactUsButton {
    width: 100%;
    margin-top: 30px;
  }

  .proofSection .title {
    text-align: start;
  }

  .proofCompanies {
    gap: 30px;
  }

  .proofLogo {
    width: 90px;
    height: 90px;
  }

  .technologies {
    gap: 30px 32px;
  }

  .technologyLogo {
    max-width: 58px;
    height: 58px;
  }

  .advantagesList {
    gap: 20px;
  }

  .advantageCard > div {
    background: none !important;
    box-shadow: none;
  }

  .contactUsButton {
    width: 100%;
  }

  .tellUsBlock {
    padding-bottom: 42vw;
    background-size: 100%;
  }
}

@media (max-width: 537px) {
  .testimonialWrapper {
    height: 380px;
  }
}

@media (max-width: 497px) {
  .testimonialWrapper {
    height: 400px;
  }
}

@media (max-width: 440px) {
  .testimonialWrapper {
    height: 440px;
  }
}

@media (max-width: 390px) {
  .testimonialWrapper {
    height: 460px;
  }
}

