.formGroup {
  position: relative;
  width: 100%;
}

.form_control {
  margin: 11px 0 0;
  width: 100%;
}

.label {
  display: block;
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 500;
  transition: transform 0.2s ease-out;
}

.validationMessage {
  font-size: 16px;
  color: var(--red);
  margin-top: 5px;
  margin-left: 16px;
}

.input_help {
  margin: 7px 0;
  opacity: 0.49;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  white-space: pre-wrap;
}

.input_help_second {
  opacity: 1;
  color: rgba(0, 0, 0, 0.67);
}

/* CHECKBOX */
.input_wrapper_checkbox {
  position: relative;
  display: flex;
  gap: 12px;
  /*align-items: center;*/
  white-space: pre-wrap;
  font-size: 20px;
  line-height: 40px;
}

.form_control_checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: relative;
  top: 8px;
  min-width: 24px;
  height: 24px;
  background: url(../../assets/images/checkmark.svg) no-repeat;
  background-size: contain;
}

.form_control_checkbox:checked ~.checkmark {
  background-image: var(--purple-gradient);
  border-radius: 4px;

}

.form_control_checkbox:checked ~ .checkmark::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 2px;
  box-sizing: content-box;
  width: 7px;
  height: 13px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* multiselect */
.multiselectWrapper {
  position: relative;
}

.multiselectWrapper li {
  white-space: nowrap;
}

.autocompleteWrapper {

}

.autocompleteWrapper :global(.MuiInput-underline):before {
  border: none !important;
}

.phoneWrapper .input_help, .phoneWrapper .validationMessage {
  position: relative;
  left: 50px;
}

.phoneWrapper :global(.react-tel-input) {
  display: flex;
  flex-direction: row-reverse;
}

.phoneWrapper :global(.input_wrapper) {
  width: 100%;
}

.phoneWrapper :global(.flag-dropdown) {
  position: static !important;
  height: 46px;
  min-width: 100px;
  margin-right: 16px;
  border: none !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}

.phoneWrapper :global(.flag-dropdown .selected-flag) {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 0;
  /*padding-left: 18px;*/
}

.phoneWrapper :global(.flag-dropdown .selected-flag):before {
  content: 'Country code';
  position: absolute;
  top: 52px;
  font-size: 12px;
  font-weight: 300;
}

.phoneWrapper :global(.arrow) {
  left: 32px !important;
  width: 12px !important;
  height: 7px !important;
  background-size: cover;
  border: none !important;
}

.phoneWrapper :global(.flag-dropdown),
.phoneWrapper :global(.flag-dropdown):hover,
.phoneWrapper :global(.flag-dropdown.open),
.phoneWrapper :global(.flag-dropdown .selected-flag),
.phoneWrapper :global(.flag-dropdown.open .selected-flag) {
  border-radius: 8px !important;
  background-color: transparent !important;
}

@media (max-width: 576px) {
  .input_wrapper_checkbox {
    gap: 15px;
    font-size: 16px;
    line-height: 19px;
  }

  .checkmark {
    top: -3px;
  }
}