.wrapper {
  position: relative;
}

.label {
  position: relative;
  display: block;
  height: 60px;
  padding-left: 16px;
  line-height: 60px;
  border-radius: 20px;
  border: 1px solid var(--gray-2);
  font-size: 20px;
  color: var(--gray-2);
  cursor: pointer;
  box-sizing: border-box;
}

.label:after {
  position: absolute;
  right: 16px;
  top: calc(50% - 12px);
  content: "";
  width: 24px;
  height: 24px;
  background: url(../../assets/images/attachment.svg) no-repeat;
  background-size: cover;
}

.selectedFile {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 60px;
  padding-left: 16px;
  font-size: 20px;
  color: var(--black);
  box-sizing: border-box;
}

.fileName {
  width: calc(100% - 90px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input {
  display: none;
}

.deleteButton {
  position: relative;
  width: 14px;
  height: 14px;
  cursor: pointer;
}


.deleteButton:before, .deleteButton:after {
  content: "";
  position: absolute;
  top: -18px;
  left: -25px;
  width: 2px;
  height: 16px;
  margin: 18px 0 15px 31px;
  border-radius: 4px;
  background-color: #000;
  transform: rotate(-315deg);
  z-index: 1;
}

.deleteButton:after {
  transform: rotate(-225deg);
}

.help {
  margin-top: 5px;
  font-size: 16px;
  color: var(--gray-2);
  text-align: right;
}

@media (max-width: 768px) {
  .label, .fileName {
    font-size: 16px;
  }
}