.accordion {
  transition: 2s;
  height: auto;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.title {
  position: relative;
  padding-left: 102px;
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  text-transform: uppercase;
  border: 0 !important;
  cursor: pointer;
}

.title:before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
  width: 30px;
  height: 18px;
  background: url(../../assets/images/purple-arrow.svg) no-repeat;
  background-size: cover;
  transition: transform .3s;
  filter: brightness(0);
}

.title:global(.ant-collapse-item-active):before {
  transform: rotate(180deg);
  filter: none;
}

.title > div {
  padding: 0 !important;
}

.accordion p {
  margin-top: 16px;
  font-size: 20px;
  line-height: 40px;
  font-weight: 400;
  color: var(--gray);
  text-transform: none;
}

.accordion :global(.ant-collapse-expand-icon) {
  display: none !important;
}

.accordion :global(.ant-collapse-content) {
  border: 0 !important;
  background-color: transparent;
}

.accordion :global(.ant-collapse-content-box) {
  padding: 0 !important;
}

@media (max-width: 768px) {
  .title {
    padding-left: 22px;
    font-size: 16px;
    line-height: 19px;
  }

  .title:before {
    width: 12px;
    height: 7px;
  }

  .accordion p {
    font-size: 16px;
    line-height: 19px;
  }
}