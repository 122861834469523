.footer {
  max-width: var(--content-width);
  width: 100%;
  padding: 80px var(--content-padding) 0;
  box-sizing: border-box;
  margin: 0 auto;
}

.visitBlock {
  display: flex;
  gap: 103px;
}

.visitImage {
  display: block;
  max-width: 531px;
  width: 100%;
  object-fit: cover;
  border-radius: 30px;
  box-shadow: 4px 4px 20px 0 #03517C26;
}

.visitAddressBlock {
  padding-top: 70px;
}

.visitTitle {
  font-size: 20px;
  font-weight: 800;
  background-image: var(--purple-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.visitCity {
  margin-top: 45px;
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.visitAddress {
  margin-top: 25px;
  color: var(--gray);
  font-size: 20px;
  line-height: 40px;
}

.visitPhone {
  color: var(--gray);
  font-size: 20px;
  line-height: 40px;
  text-decoration: none;
}

.bottomPart {
  display: flex;
  align-items: center;
  gap: 210px;
  height: 180px;
  margin-top: 70px;
}

.logo {
  display: block;
  text-decoration: none;
  width: 220px;
  min-height: 75px;
  background: url(../../assets/images/logo.png) no-repeat;
  background-size: contain;
}

/*.footerLinks {*/
  /*display: flex;*/
  /*gap: 98px;*/
  /*color: var(--gray);*/
  /*font-size: 18px;*/
/*}*/

.copyright {
  word-spacing: 13px;
  color: var(--gray);
  font-size: 18px;
}

.menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li:first-child {
  padding-right: 98px;
}

.menu li:nth-child(2) {
  padding-right: 26px;
}

.menuLink {
  color: var(--gray);
  font-size: 18px;
  text-decoration: none;
}

.rightPart {
  display: flex;
  flex-grow: 1;
  gap: 98px;
}

@media (max-width: 991px) {
  .visitBlock {
    flex-direction: column;
    align-items: center;
    gap: 27px;
  }

  .visitAddressBlock {
    padding-top: 0;
  }

  .bottomPart {
    gap: 0;
  }

  .rightPart {
    justify-content: center;
  }
}

@media (max-width: 800px) {
  .footer {
    padding-bottom: 80px;
  }

  .rightPart, .rightPart nav {
    width: 100%;
  }

  .menu {
    justify-content: space-between;
  }

  .menu li {
    padding: 0 !important;
  }

  .bottomPart {
    flex-direction: column;
    align-items: end;
    gap: 30px;
    height: auto;
  }
}

@media (max-width: 678px) {
  .visitTitle {
    font-size: 16px;
  }

  .visitCity {
    margin-top: 20px;
    font-size: 16px;
  }

  .visitAddress {
    margin-top: 10px;
    font-size: 16px;
    line-height: 19px;
  }

  .visitPhone {
    font-size: 16px;
    line-height: 19px;
  }

  .footer {
    padding: 72px 15px 65px;
  }
}

@media (max-width: 576px) {
  .visitBlock {
    align-items: start;
  }

  .footer {
    padding: 70px 15px 57px;
  }

  .copyright, .menuLink {
    font-size: 12px;
  }
}