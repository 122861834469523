.form {
  display: flex;
  flex-direction: column;
  max-width: 590px;
  width: 100%;
  padding: 24px 24px 13px 24px;
  border-radius: 30px;
  background-image: var(--white);
  box-shadow: 4px 4px 20px 0 #03517C26;
  box-sizing: border-box;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.formRow {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.input {
  width: 100%;
  height: 60px;
  padding: 0 45px 0 16px;
  outline: none;
  border-radius: 20px;
  box-sizing: border-box;
  border: 1px solid var(--gray-2);
  font-size: 20px;
}

.input::placeholder {
  color: var(--gray-2);
}

.conditions {
  margin-top: 22px;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-2);
}

.checkbox {
  margin-top: 24px;
}

.submit {
  width: 183px;
  height: 56px;
  margin-top: 48px;
  margin-left: auto;
  line-height: 56px;
  background: linear-gradient(94.57deg, #FF77EF 8.81%, #AA52F8 93.67%);
  box-shadow: 4px 4px 15px 0 #525AE233;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 900;
  color: var(--white);
  border-radius: 28px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .input {
    border-radius: 10px;
  }
}

@media (max-width: 576px) {
  .submit {
    width: 100%;
  }
}