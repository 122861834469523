:root {
  --black: #342D42;
  --gray: #918B9C;
  --gray-2: #A9A5B7;
  --purple-gradient: linear-gradient(94.57deg, #FF77EF 8.81%, #AA52F8 93.67%);
  --red: #ED6165;
  --white: #FFF;
  --content-width: 1230px;
  --content-padding: 15px;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  color: var(--black);
}

p, h1, h2 {
  margin: 0;
}

button {
  border: none;
  padding: 0;
}

.bold {
  font-weight: bold;
}

.hidden {
  display: none;
}
