.wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: auto;
  margin: 0 auto;
  background-color: #fff;
  box-sizing: border-box;
}

.authContent {
  padding: 0 25px;
}

:global(.ant-modal-mask) {
  background-image: var(--purple-gradient) !important;
  opacity: 0.7;
}

.modalWrapper :global(.ant-modal) {
  padding: 0 15px;
  max-width: 638px !important;
  width: 100% !important;
  min-height: 464px !important;
  font-family: 'Lato', sans-serif !important;
}

.modalWrapper :global(.ant-modal-content) {
  min-height: 464px !important;
  border-radius: 40px;
  padding: 65px 70px 80px;
  box-sizing: border-box;
}

.modalWrapper :global(.ant-modal-close) {
  top: 44px !important;
  right: 54px !important;
  width: 30px;
  height: 30px;
}

.modalClose {
  width: 30px;
  height: 30px;
  background: url(../../assets/images/cross.svg) no-repeat;
  background-size: cover;
}

.modalWrapper :global(.ant-btn-primary) {
  width: 183px;
  height: 56px;
  border-radius: 28px;
  background-image: var(--purple-gradient);
  box-shadow: 4px 4px 15px 0 #525AE233;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: 900;
  margin-left: 0 !important;
}

.modalWrapper :global(.ant-modal-footer) {
  text-align: center;
  margin-top: 22px !important;
}

.modalWrapper :global(.ant-modal-body) {
  min-height: 185px;
}

@media(max-width: 768px) {
  .modalWrapper :global(.ant-modal) {
    max-width: 360px !important;
    min-height: 293px !important;
  }

  .modalWrapper :global(.ant-modal-content) {
    border-radius: 10px;
    min-height: 293px !important;
    padding: 30px 15px 15px;
  }

  .modalWrapper :global(.ant-modal-close) {
    top: 20px !important;
    right: 20px !important;
    width: 20px;
    height: 20px;
  }

  .modalClose {
    width: 20px;
    height: 20px;
  }

  .modalWrapper :global(.ant-btn-primary) {
    width: 100%;
  }

  .modalWrapper :global(.ant-modal-footer) {
    margin-top: 5px !important;
  }
}